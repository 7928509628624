.margin-nav {
    margin: 0px 15px;
}

#navbarNavDropdown {
    place-content: center;
}

.cursor-pointer {
    cursor: pointer;
}

.input_field-height,
.css-1s2u09g-control {
    height: 45px;
}

.css-1s2u09g-control {
    border-radius: 50rem !important;
}

.category-input-field {
    border: 1px solid grey;
    display: flex;
    align-items: center;
}

.nav-link {
    font-size: 12px;
    text-transform: uppercase;
}

.nav-link:hover {
    color: var(--secondary-color) !important;
}

.react-multi-carousel-item {
    width: 100%;
}

.page-link {
    color: var(--primary-color);
}

.top-navbar {
    align-items: center;
}

.max-width-content {
    width: max-content;
}

.admin-logo {
    height: 3rem;
}

.admin-logout-btn {
    margin-top: 30vh;
    display: flex;
    margin-inline: auto;
}

.page-not-found {
    height: 100vh;
    padding-top: 50%;
}

.page-not-found-img {
    display: block;
    margin: auto;
}

.image-gallery-slide .image-gallery-image {
    object-fit: cover !important;
    height: 350px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 50px;
}

.form-control {
    border: 1px solid black;
}

.vendor-images {
    width: 200px;
}

.promotional-images {
    width: -webkit-fill-available;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.upload-btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 8px;
    border-radius: 8px;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.img_upload-btn {
    font-size: 8px;
}

.vendor_store-images {
    width: -webkit-fill-available;
}

.card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.no-data-found {
    width: 25%;
}


/* Chat Box */

.chat-modal {
    float: right;
    align-items: flex-end !important;
}

.chat-modal .modal-body {
    padding: 0rem !important;
}

.main-wrapper {
    background-color: #080a0d;
    width: 100%;
    display: grid;
    place-items: center;
}

::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: #27282f;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #40404b;
    border-radius: 30px;
}


/*-----------------------LIVE CHAT-------------------------*/

.cgl-live-chat {
    max-width: 550px;
}

.cgl-live-chat .chat-wrapper {
    background-color: #141921;
    border: 8px solid #141921;
    box-sizing: border-box;
}

.cgl-live-chat .chat-wrapper .chat-title {
    padding: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #9fadc5;
}

.cgl-live-chat .chat-wrapper .chat-view {
    background-color: #11151a;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
}

.cgl-live-chat .chat-wrapper .chat-item {
    padding: 5px;
}

.cgl-live-chat .chat-wrapper .chat-view img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.cgl-live-chat .chat-wrapper .chat-view .sender-name a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #adadad;
}

.cgl-live-chat .chat-wrapper .chat-view .sender-name a:hover {
    color: #ffffff;
    font-weight: bold;
}

.cgl-live-chat .chat-wrapper .chat-view .admin-tag a {
    background-color: #4368ea !important;
    color: #ffffff !important;
    border-radius: 9px;
    padding: 0 8px;
}

.cgl-live-chat .chat-wrapper .chat-view .chat-time {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    padding-left: 8px;
    padding-right: 8px;
    color: #585c61;
}

.cgl-live-chat .chat-wrapper .chat-view .chat-time i {
    display: none;
}

.cgl-live-chat .chat-wrapper .chat-view .chat-pinned {
    border: 2px solid #7389dc;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #11151a;
}

.chat-sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.cgl-live-chat .chat-wrapper .chat-view .chat-pinned .chat-time i {
    display: inline;
}

.cgl-live-chat .chat-wrapper .chat-view .chat-text {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 17px;
    padding: 3px;
    color: #9fadc5;
}

.cgl-live-chat .chat-wrapper .chat-message {
    background-color: #23272d;
    padding: 6px;
}

.cgl-live-chat .chat-wrapper .chat-message input {
    background-color: transparent;
    border: none !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #585c61;
    outline: none !important;
}

.cgl-live-chat .chat-wrapper .chat-message button {
    background-color: transparent;
    border: none;
    outline: none;
}

.cgl-live-chat .chat-wrapper .dropdown-menu {
    background-color: #141921;
}

.cgl-live-chat .chat-wrapper .dropdown-menu .dropdown-item {
    color: #9fadc5;
    font-style: normal;
    font-weight: 300;
}

.cgl-live-chat .chat-wrapper .dropdown-menu .dropdown-item:hover {
    background-color: #1c232e;
}

@media (max-width: 450px) {
    .cgl-live-chat .chat-wrapper .chat-view {
        max-height: 300px;
    }
    .cgl-live-chat .chat-wrapper .chat-view img {
        width: 30px;
        height: 30px;
    }
    .cgl-live-chat .chat-wrapper .chat-view .sender-name a {
        font-size: 10px;
        line-height: 10px;
    }
    .cgl-live-chat .chat-wrapper .chat-view .chat-time {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .cgl-live-chat .chat-wrapper .chat-view .chat-text {
        font-size: 12px;
        line-height: 12px;
        padding: 3px;
    }
}

.dropdown-btn {
    background-color: transparent;
    color: #585c61;
    border: none;
    outline: none !important;
}


/* Vendor Chat List */

.main-user-chat {
    background-color: #080a0d;
    width: 100%;
    display: grid;
}

.user-chat-list {
    max-width: 100%;
}

.user-chat-list .chat-wrapper {
    background-color: #141921;
    border: 8px solid #141921;
    box-sizing: border-box;
}

.user-chat-list .chat-wrapper .chat-title {
    padding: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #9fadc5;
}

.user-chat-list .chat-wrapper .chat-view {
    background-color: #11151a;
    display: flex;
    flex-direction: column;
    /* min-height: 555px;
    max-height: 555px; */
    overflow-y: auto;
}

.user-chat-list .chat-wrapper .chat-item {
    padding: 5px;
}

.user-chat-list .chat-wrapper .chat-view img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.user-chat-list .chat-wrapper .chat-view .sender-name a {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #adadad;
}

.user-chat-list .chat-wrapper .chat-view .sender-name a:hover {
    color: #ffffff;
    font-weight: bold;
}

.user-chat-list .chat-wrapper .chat-view .admin-tag a {
    background-color: #4368ea !important;
    color: #ffffff !important;
    border-radius: 9px;
    padding: 0 8px;
}

.user-chat-list .chat-wrapper .chat-view .chat-time {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    padding-left: 8px;
    padding-right: 8px;
    color: #585c61;
}

.user-chat-list .chat-wrapper .chat-view .chat-time i {
    display: none;
}


/* .user-chat-list .chat-wrapper .chat-view .chat-pinned {
    border: 2px solid #7389DC;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #11151A;
} */

.chat-sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.user-chat-list .chat-wrapper .chat-view .chat-pinned .chat-time i {
    display: inline;
}

.user-chat-list .chat-wrapper .chat-view .chat-text {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 17px;
    padding: 3px;
    color: #9fadc5;
    text-align: left;
}

.user-chat-list .chat-wrapper .chat-message {
    background-color: #23272d;
    padding: 6px;
}

.user-chat-list .chat-wrapper .chat-message input {
    background-color: transparent;
    border: none !important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #585c61;
    outline: none !important;
}

.user-chat-list .chat-wrapper .chat-message button {
    background-color: transparent;
    border: none;
    outline: none;
}

.user-chat-list .chat-wrapper .dropdown-menu {
    background-color: #141921;
}

.user-chat-list .chat-wrapper .dropdown-menu .dropdown-item {
    color: #9fadc5;
    font-style: normal;
    font-weight: 300;
}

.user-chat-list .chat-wrapper .dropdown-menu .dropdown-item:hover {
    background-color: #1c232e;
}

@media (max-width: 450px) {
    /* .user-chat-list .chat-wrapper .chat-view {
        max-height: 300px;
    } */
    .user-chat-list .chat-wrapper .chat-view img {
        width: 30px;
        height: 30px;
    }
    .user-chat-list .chat-wrapper .chat-view .sender-name a {
        font-size: 10px;
        line-height: 10px;
    }
    .user-chat-list .chat-wrapper .chat-view .chat-time {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .user-chat-list .chat-wrapper .chat-view .chat-text {
        font-size: 12px;
        line-height: 12px;
        padding: 3px;
    }
}

.dropdown-btn {
    background-color: transparent;
    color: #585c61;
    border: none;
    outline: none !important;
}

.chat-border-bottom {
    border-bottom: 1px solid rgb(35 35 35);
}

.vendor-message {
    flex-flow: row-reverse;
}

.active-request {
    color: #fff;
    background-color: var(--fourth-color);
}

.request-toggle {
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

.request-toggle .incoming-btn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.request-toggle .outgoing-btn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.accordion-button:not(.collapsed) {
    color: var(--secondary-color);
}

.admin-navbar .dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.admin-navItem {
    color: var(--secondary-color) !important;
}

.NavDropdown_Item {
    color: white;
    text-decoration: none;
}

.NavDropdown_Item:hover {
    color: var(--secondary-color);
}

.testimonial-box {
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    cursor: pointer;
}

.profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.name-user strong {
    color: #3d3d3d;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}

.name-user span {
    color: #979797;
    font-size: 0.8rem;
}

.client-comment p {
    font-size: 0.9rem;
    color: #4b4b4b;
}

.testimonial-box:hover {
    transform: translateY(-10px);
    transition: all ease 0.3s;
}

@media (max-width: 1060px) {
    .testimonial-box {
        width: 45%;
        padding: 10px;
    }
}

@media (max-width: 790px) {
    .testimonial-box {
        width: 100%;
    }
    .testimonial-heading h1 {
        font-size: 1.4rem;
    }
}

@media (max-width: 340px) {
    .box-top {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .reviews {
        margin-top: 10px;
    }
}

::selection {
    color: #ffffff;
    background-color: #252525;
}

.number-pagination {
    padding: 0px 5px;
}

.number-pagination a {
    text-decoration: none;
    color: grey;
}


/* Verify Email */

.verify-email-page {
    background-color: #d3d3d3 !important;
}

.contact-form {
    padding: 30px 40px;
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 400px;
}

.contact-form .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}

.contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .vendor_carousel-img {
        width: 100%;
        height: 200px;
    }
    .brand_carousel-img {
        width: 150px;
        height: 130px;
    }
    .react-multi-carousel-list {
        height: 200px;
    }
    .card-width {
        width: 100%;
    }
    .social-ic {
        font-size: 25px;
    }
    .card-img-top {
        height: 8rem;
    }
    .vendor-name {
        font-size: 14px;
    }
    .page-not-found-img {
        width: 100%;
    }
    .image-gallery-slide .image-gallery-image {
        object-fit: cover !important;
        height: 200px;
    }
    .upload-btn {
        font-size: 32px;
    }
    .user-chat-list .chat-wrapper .chat-view {
        background-color: #11151a;
        display: flex;
        flex-direction: column;
        /* min-height: 400px !important;
        max-height: 400px !important; */
        overflow-y: auto;
    }
    .data-not-found {
        height: 70vh;
    }
    .h-sm-24vh {
        height: 24vh;
    }
    .h-sm-76vh {
        height: 76vh;
    }
    .h-sm-10 {
        height: 10%;
    }
    .h-sm-80 {
        height: 80%;
    }
    .user-chat-list .chat-wrapper .chat-title {
        font-size: 16px;
        line-height: 28px;
    }
    .request-toggle .incoming-btn {
        font-size: 12px;
    }
    .request-toggle .outgoing-btn {
        font-size: 12px;
    }
    .css-1s2u09g-control {
        font-size: 12px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
    .vendor_carousel-img {
        width: 100%;
        height: 250px;
    }
    .brand_carousel-img {
        width: 220px;
        height: 220px;
    }
    .social-icon {
        font-size: 20px;
    }
    .social-ic {
        font-size: 25px;
    }
    .card-img-top {
        height: 8rem;
    }
    .page-not-found {
        padding-top: 25%;
    }
    .data-not-found {
        height: 80vh;
    }
    /* .data-not-found-title {} */
    .h-sm-24vh {
        height: 15vh;
    }
    .h-sm-76vh {
        height: 85vh;
    }
    .h-md-5 {
        height: 5%;
    }
    .h-md-90 {
        height: 90%;
    }
}

@media screen and (min-width: 992px) {
    .admin_panel-navbar {
        height: 100vh;
    }
    .admin_navbar-brand {
        display: block;
        margin: 0px 0px 50px 0px;
        border-bottom: 1px solid white;
        line-height: 150px;
    }
    .admin-logo {
        height: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .vendor-carousel {
        text-align: -webkit-center;
    }
    .filter-section {
        margin: auto;
    }
    .vendor_carousel-img {
        width: 100%;
        height: 650px;
    }
    .brand_carousel-img {
        width: 249px;
        height: 224px;
    }
    .card-width {
        width: 12rem;
    }
    .social-icon {
        font-size: 20px;
    }
    .social-ic {
        font-size: 25px;
    }
    .user-modal {
        display: inline-block;
        float: right;
        margin-right: 6%;
        padding-top: 40px;
        width: 230px;
    }
    .register_brand-modal {
        display: flex;
        width: 350px;
    }
    .top-navbar {
        align-items: center;
        width: 100%;
    }
    .card-img-top {
        height: 8rem;
    }
    .admin-logo {
        height: 2.8rem;
    }
    .page-not-found {
        padding-top: 10%;
    }
    .vendor-name {
        font-size: 16px;
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 60px !important;
    }
    .navbar-section {
        height: 100vh;
    }
    .search-data-not-found {
        height: 88vh;
    }
    .nav-item:last-child .dropdown-submenu-container .dropdown-menu {
        right: 100%;
        margin-right: 1px;
        left: auto;
        top: 0;
    }
    .nav-item:nth-last-child(2) .dropdown-submenu-container .dropdown-menu {
        right: 100%;
        margin-right: 1px;
        left: auto;
        top: 0;
    }
    .nav-item:nth-last-child(3) .dropdown-submenu-container .dropdown-menu {
        right: 100%;
        margin-right: 1px;
        left: auto;
        top: 0;
    }
    .h-10vh {
        height: 10vh;
    }
    .h-90vh {
        height: 90vh;
    }
    .h-75vh {
        height: 100vh;
    }
    .h-5 {
        height: 5%;
    }
    .h-90 {
        height: 90%;
    }
    .h-100 {
        height: 100%;
    }
}

figure.snip1171 {
    /* font-family: 'Raleway', Arial, sans-serif; */
    /* position: relative; */
    overflow: hidden;
    /* margin: 10px; */
    /* width: 100%; */
    background: #000000;
    color: #333;
    /* text-align: left; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

figure.snip1171 * {
    /* -webkit-box-sizing: border-box;
    box-sizing: border-box; */
    -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

figure.snip1171 img {
    /* width: 100%; */
    /* vertical-align: top; */
    height: 125px;
}

figure.snip1171 figcaption {
    /* position: relative; */
    /* background-color: #ffffff; */
    padding: 20px 25px 50px;
}

figure.snip1171 p {
    font-size: 0.8em;
    font-weight: 500;
    /* text-align: left; */
    /* margin: 0; */
    line-height: 1.6em;
}

figure.snip1171 a {
    /* text-decoration: none; */
    /* position: absolute; */
    /* bottom: 0; */
    /* right: 0; */
    background-color: #000000;
    line-height: 30px;
    padding: 0 10px;
    /* color: #ffffff; */
    font-weight: 800;
    font-size: 0.7em;
    /* text-transform: uppercase; */
}

figure.snip1171 a:before {
    position: absolute;
    right: 100%;
    border-style: solid;
    border-width: 0 0 40px 12px;
    border-color: transparent transparent #000000;
    content: "";
    top: 0;
}

figure.snip1171.orange {
    background-color: #d67118;
}

figure.snip1171.orange a {
    background-color: #d67118;
}

figure.snip1171.orange a:before {
    border-color: transparent transparent #d67118;
}

figure.snip1171:hover img,
figure.snip1171.hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.dropdown-menu {
    background: linear-gradient(45deg, #41464b, transparent);
    transform: translate(0px, 0px);
}

.dropdown-item {
    color: white;
    font-size: 16px;
    padding-left: 20px;
}

.dropdown-item:hover {
    color: #d67118;
}

.dropdown-submenu-container .dropdown-menu {
    margin-left: 4px;
}


.product-details-title{
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-right: 60px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

.product-details-description-title{
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

.weekDays-selector input {
    display: none!important;
  }
  
  .weekDays-selector input[type=checkbox] + label {
    display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 40px;
    width: 30px;
    margin-right: 3px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  
  .weekDays-selector input[type=checkbox]:checked + label {
    background: #2AD705;
    color: #ffffff;
  }