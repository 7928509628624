.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.text-left {
    text-align: left !important;
}

.height-45 {
    height: 45px;
}

.error-msg {
    color: red;
}

.div-500 {
    max-width: 500px;
    width: 100%;
}

.div-center {
    display: flex;
    justify-content: center;
}

.right-div-item {
    text-align: right;
}

.action-button {
    margin: 0 5px;
    cursor: pointer;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.application-loading-initial {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed !important;
    z-index: 1000;
    text-align: center;
    background-color: #ffff;
    z-index: 20000;
    /* opacity: 0.9; */
    top: 0;
    padding: 20px;
}
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ea641d;
    color: #ea641d;
    box-shadow: 9999px 0 0 -5px #ea641d;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ea641d;
    color: #ea641d;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #ea641d;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #ea641d;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #ea641d;
    }
    30% {
        box-shadow: 9984px 0 0 2px #ea641d;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #ea641d;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #ea641d;
    }
    30% {
        box-shadow: 9999px 0 0 2px #ea641d;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #ea641d;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #ea641d;
    }
    30% {
        box-shadow: 10014px 0 0 2px #ea641d;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #ea641d;
    }
}
.filter-home{
    width: 200px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}