body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root,
:host {
    --primary-color: #656565;
    /* --secondary-color: #FF7900; */
    --secondary-color: #EA641D;
    --third-color: #F8F8F8;
    --fourth-color: #181A18;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-grey {
    background-color: var(--primary-color);
}

.btn-outline-orange {
    color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
}

.btn-orange {
    background-color: var(--secondary-color) !important;
    color: var(--third-color) !important;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.coupon-details {
    text-align: left;
    border: 1px solid #6e6464;
    border-radius: 14px;
    padding: 13px 1px;
    margin-top: 14px;
}

.coupon-details-close {
    text-align: right;
    margin-top: -8px;
}